import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { RouterFacade } from '@core/shared/data-access';
import { PaginationMetadata } from '@core/shared/domain';
import { NotificationService, filterNull } from '@core/shared/util';
import { PagedDataFetcher, PagedDataSource } from '@core/ui';
import { PlatformSupplier, PlatformSupplierBasic, UpdatePlatformSupplier } from '@mp/supplier-management/common/domain';
import { SUPPLIER_ROUTE_PARAM_MAP_KEY } from '@mp/supplier-management/platform-suppliers/util';

import { PlatformSupplierService } from './platform-supplier.service';

type FetchParams = { searchTerm: string };

@Injectable()
export class PlatformSupplierFacade {
  readonly id$: Observable<string>;

  private readonly _errors$: BehaviorSubject<Record<string, Array<string>> | undefined> = new BehaviorSubject<
    Record<string, Array<string>> | undefined
  >(undefined);
  readonly errors$: Observable<Record<string, Array<string>> | undefined> = this._errors$.asObservable();

  private readonly _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoading$: Observable<boolean> = this._isLoading$.asObservable();

  private readonly _pagination$: BehaviorSubject<PaginationMetadata | undefined> = new BehaviorSubject<
    PaginationMetadata | undefined
  >(undefined);
  readonly pagination$: Observable<PaginationMetadata | undefined> = this._pagination$.asObservable();

  private readonly _lieferant$: BehaviorSubject<PlatformSupplier | undefined> = new BehaviorSubject<
    PlatformSupplier | undefined
  >(undefined);
  readonly lieferant$: Observable<PlatformSupplier | undefined> = this._lieferant$.asObservable();

  private readonly _isProcessingExport$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isProcessingExport$: Observable<boolean> = this._isProcessingExport$.asObservable();

  readonly dataSource: PagedDataSource<PlatformSupplierBasic, FetchParams>;

  constructor(
    private readonly routerFacade: RouterFacade,
    private readonly toaster: NotificationService,
    private readonly service: PlatformSupplierService,
  ) {
    this.id$ = routerFacade.routeParam$(SUPPLIER_ROUTE_PARAM_MAP_KEY).pipe(filterNull());

    this.dataSource = this.buildDataSource(service);
  }

  private buildDataSource(service: PlatformSupplierService): PagedDataSource<PlatformSupplierBasic, FetchParams> {
    const fetcher: PagedDataFetcher<PlatformSupplierBasic, FetchParams> = (pagination, { searchTerm }) =>
      service.getAll({ ...pagination, searchTerm }).pipe(
        tap(({ pagination }) => this._pagination$.next(pagination)),
        map(({ data, pagination }) => ({
          results: data,
          hasMore: pagination.currentPage < pagination.totalPageCount,
        })),
      );

    const initialParams = { searchTerm: '' };

    return new PagedDataSource<PlatformSupplierBasic, FetchParams>(fetcher, initialParams);
  }

  refreshDataSource(): void {
    this.dataSource.reset();
    this.dataSource.fetchMore().subscribe();
  }

  get(id: string): void {
    this._isLoading$.next(true);
    this.service.get(id).subscribe({
      next: (lieferant) => {
        this._lieferant$.next(lieferant);
        this._isLoading$.next(false);
      },
    });
  }

  update(id: string, updateDto: UpdatePlatformSupplier): void {
    this.service.update(id, updateDto).subscribe({
      next: (lieferant) => {
        this.toaster.toastSuccess('Die Änderungen wurden gespeichert.');

        this._lieferant$.next(lieferant);
        this._errors$.next(undefined);

        this.refreshDataSource();
      },
      error: (error: unknown) => this._errors$.next((error as HttpErrorResponse).error.errors),
    });
  }

  exportAll(): void {
    this._isProcessingExport$.next(true);
    this.service.exportAll().subscribe({
      next: () => {
        this._isProcessingExport$.next(false);
        this.toaster.toastSuccess(
          'Der Plattformlieferanten Export wurde per E-Mail versendet. Bitte prüfe dein Postfach.',
        );
      },
      error: () => {
        this._isProcessingExport$.next(false);
        this.toaster.toastDanger('Der Export ist leider fehlgeschlagen. Bitte versuche es nochmal.');
      },
    });
  }

  navigateBack(): void {
    this.routerFacade.navigateBack();
  }
}
